<template>
  <v-btn
    :ripple="false"
    :to="to"
    class="text-decoration-none px-0"
    plain
  >
    <v-icon left small v-text="icons.back" />
    <span class="text-caption">{{ text }}</span>
  </v-btn>
</template>

<script>
import {mdiChevronLeftCircleOutline} from '@mdi/js'

export default {
  name: 'AppLinkBackToDeposits',
  props: {
    to: {type: [Object, String], required: true},
    text: {type: String, required: true},
  },
  data () {
    return {
      icons: {
        back: mdiChevronLeftCircleOutline,
      }
    }
  }
}
</script>
